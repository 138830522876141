.about-container {
    max-width: 90%;
    margin: 0 auto;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
}

.about-container h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    text-align: center;
}

.about-container h2 {
    font-size: 1.8em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: #2c3e50;
    border-bottom: 0.125rem solid #e1e1e1;
    padding-bottom: 0.3em;
}

.about-container p {
    font-size: 1em;
    margin-bottom: 1em;
    text-align: justify;
}

.about-container ul {
    margin-left: 1.5em;
    margin-bottom: 1em;
}

.about-container li {
    margin-bottom: 0.5em;
}

.about-container a {
    color: #3498db;
    text-decoration: none;
}

.about-container a:hover {
    text-decoration: underline;
}

.latex-reference {
    background-color: #f7f7f7;
    padding: 1rem;
    border: 0.0625rem solid #e1e1e1;
    overflow-x: auto;
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.9em;
    margin-bottom: 1em;
    border-radius: 0.25rem;
}

@media (max-width: 600px) {
    .about-container {
        padding: 1rem;
    }

    .about-container h1 {
        font-size: 2em;
    }

    .about-container h2 {
        font-size: 1.5em;
    }
}