.joke-card {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 5%;
    margin: 2%;
    border-radius: 0.25em;
    flex: 1;
}

.joke-card p {
    margin-bottom: 2em;
    font-size: 1.3em;
}

.share-icon {
    position: absolute;
    bottom: 2%;
    right: 2%;
    background: none;
    border: none;
    cursor: pointer;
}

@media (max-width: 600px) {
    .joke-card {
        margin: 0 0%;
        width: 50%;
    }
}
