.leaderboard-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.leaderboard-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.leaderboard-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.leaderboard-table th,
.leaderboard-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.leaderboard-table th {
    background-color: #f2f2f2;
    color: #333;
    position: relative;
}

.leaderboard-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.leaderboard-table tr:hover {
    background-color: #e2e2e2;
}

.leaderboard-table th:first-child,
.leaderboard-table td:first-child {
    width: 60px;
}

.tooltip {
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: visible;
    opacity: 1;
    width: 300px;
    background-color: #fff;
    color: #333;
    text-align: left;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -150px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7.5px;
    border-width: 7.5px;
    border-style: solid;
    border-color: #ccc transparent transparent transparent;
}

.ci {
    white-space: nowrap;
}

@media screen and (max-width: 600px) {
    .leaderboard-container {
        padding: 4px;
    }
    .leaderboard-table th,
    .leaderboard-table td {
        padding: 4px;
    }
    .ci {
      display: block;
    }
}
