.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding: 1em;
    background-color: #333;
    color: #fff;
    position: fixed;
    width: 100%;
    bottom: 0;
}

.footer-link {
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}