.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: #333;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.header h1 {
    margin: 0;
}

.burger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 1100;
}

.burger .line {
    width: 100%;
    height: 0.25rem;
    background-color: #fff;
    transition: all 0.3s ease;
}

.rotate1 {
    transform: rotate(45deg) translate(5px, 5px);
}

.fade {
    opacity: 0;
}

.rotate2 {
    transform: rotate(-45deg) translate(5px, -5px);
}

/* Menu Styles */
.menu {
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 0;
    margin-right: 0.5rem;
}

.menu li {
    margin: 0;
    margin-right: 0.5rem;
}

.menu a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.menu a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .burger {
        margin-right: 2rem;
        display: flex;
    }

    .menu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #333;
        flex-direction: column;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
        z-index: 1000;
    }

    .menu-open {
        max-height: 500px;
    }

    .menu li {
        text-align: center;
        padding: 1rem 0;
    }
}