.top-jokes-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.top-jokes-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.top-jokes-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.top-jokes-table th,
.top-jokes-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.top-jokes-table th {
    background-color: #f2f2f2;
    color: #333;
    position: relative;
}

.top-jokes-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.top-jokes-table tr:hover {
    background-color: #e2e2e2;
}

.top-jokes-table th:first-child,
.top-jokes-table td:first-child {
    width: 60px;
}

@media screen and (max-width: 600px) {
    .top-jokes-container {
        padding: 4px;
    }

    .top-jokes-table th,
    .top-jokes-table td {
        padding: 4px;
    }
}

.about-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 2rem;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
}

.about-container p {
    font-size: 1em;
    margin-bottom: 1em;
    text-align: justify;
}

.about-container ul {
    margin-left: 1.5em;
    margin-bottom: 1em;
}

.about-container li {
    margin-bottom: 0.5em;
}

.about-container a {
    color: #3498db;
    text-decoration: none;
}

.about-container a:hover {
    text-decoration: underline;
}

.share-icon-top-jokes {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
}

.share-icon-top-jokes:hover svg {
    filter: brightness(0.8);
}
