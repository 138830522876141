.joke-comparison {
  text-align: center;
  padding: 2%;
}

.jokes-container {
  display: flex;
  justify-content: center;
  gap: 2%;
  margin-top: 2%;
  width: 100%;
}

.additional-options {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 3%;
}

.additional-options button {
  flex: 1;
  margin: 1%;
  padding: 3%;
  font-size: 1em;
  white-space: normal;
  word-wrap: break-word;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0.25em;
  cursor: pointer;
}

.additional-options .button {
  background-color: #5695fc;
}

.additional-options .button:hover {
  background-color: #337ffb;
}

.additional-options .both-button {
  background-color: #00b33c;
}

.additional-options .both-button:hover {
  background-color: #009933;
}

.additional-options .neither-button {
  background-color: #ff5c5c;
}

.additional-options .neither-button:hover {
  background-color: #f93838;
}